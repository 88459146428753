<template>
    <v-container>
        <!-- Reports List View -->
        <div v-if="!selectedReport">
            <v-row no-gutters>
                <!-- Header -->
                <v-col cols="12">
                    <v-subheader class="text-h5 my-4">
                        <v-divider class="mr-4"></v-divider>
                        <v-icon color="primary" class="mr-2" left size="32">
                            mdi-chart-box-outline
                        </v-icon>
                        Análisis de datos
                        <v-divider class="ml-4"></v-divider>
                    </v-subheader>
                </v-col>

                <!-- Reports Grid -->
                <v-col cols="12" sm="6" md="4" lg="4" v-for="(group, groupName) in groupedReports" :key="groupName"
                    class="pa-2">
                    <v-card class="mx-auto fill-height rounded-lg has-submenu" :elevation="2" hover>
                        <v-card-title class="pt-6 pb-2 d-flex align-center">
                            <v-avatar color="primary" size="42" class="mr-3 elevation-1">
                                <v-icon dark size="24">{{ groupIconHash[groupName] || "mdi-file-document-outline"
                                }}</v-icon>
                            </v-avatar>
                            <span class="text-h6">{{ groupName }}</span>
                        </v-card-title>

                        <v-card-text class="pb-3 pt-2">
                            <v-list class="pa-0">
                                <v-list-item v-for="report in group" :key="report.id" @click="selectReport(report)"
                                    class="px-3 rounded-lg mb-1 transition-swing" link>
                                    <v-list-item-content>
                                        <v-list-item-title class="subtitle-2">{{ report.title }}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-icon small>mdi-chevron-right</v-icon>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </div>

        <!-- Report Detail View -->
        <div v-else>
            <v-card class="rounded-lg">
                <v-card-title class="d-flex align-center">
                    <v-btn icon @click="closeReport" class="mr-2">
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    {{ selectedReport.title }}
                </v-card-title>
                <v-divider></v-divider>

                <v-card-text class="pa-4">
                    <!-- Report Components -->
                    <component :is="reportComponent" v-if="reportComponent" />
                    <v-alert v-else type="warning" text>
                        Reporte no disponible
                    </v-alert>
                </v-card-text>
            </v-card>
        </div>
    </v-container>
</template>

<script>
import { getFirestore, collection, getDocs } from "firebase/firestore";
import Alerts from "@/views/Alerts.vue";


import NewSubscriptions from "@/components/reports/NewSubscriptions.vue";
import SatisfactionSurvey from "@/components/reports/SatisfactionSurvey.vue";
import AssistancesPerUsertypeVue from "@/components/reports/AssistancesPerUsertype.vue";

import ScheduleStats from "@/components/reports/ScheduleStats.vue";
import UsersAge from "@/components/reports/UsersAge.vue";
import UsersInRisk from "@/components/reports/UsersInRisk.vue";

import UserPaselibreTransitions from "@/components/reports/UserPaselibreTransitions.vue";
import UsersRankingVue from "@/components/reports/UsersRanking.vue";

import ActiveUsersHistory from "@/components/reports/ActiveUsersHistory.vue";

import AdminDashboard from "@/components/dashboard/AdminDashboard.vue";



export default {
    components: {
        Alerts,
        NewSubscriptions,
        SatisfactionSurvey,
        AssistancesPerUsertypeVue,
        ScheduleStats,
        UsersAge,
        UsersInRisk,
        UserPaselibreTransitions,
        UsersRankingVue,
        ActiveUsersHistory,
        AdminDashboard,
    },
    data() {
        return {
            reports: [],
            selectedReport: null,
            reportComponent: null,
            groupIconHash: {
                Socios: "mdi-account-multiple",
                Asistencias: "mdi-account-check",
                Alertas: "mdi-alert",
                Pagos: "mdi-cash",
            }
        };
    },
    computed: {
        groupedReports() {
            return this.reports.reduce((groups, report) => {
                const groupName = report.group || "Other";
                if (!groups[groupName]) groups[groupName] = [];
                groups[groupName].push(report);
                return groups;
            }, {});
        }
    },
    methods: {
        async fetchReports() {
            const db = getFirestore();
            const querySnapshot = await getDocs(collection(db, "reports"));

            this.reports = querySnapshot.docs.map(doc => {
                return { id: doc.id, ...doc.data() };
            });
        },
        selectReport(report) {
            this.selectedReport = report;
            this.reportComponent = this.getReportComponent(report.title);
        },
        closeReport() {
            this.selectedReport = null;
            this.reportComponent = null;
        },
        generateFakeReports() {
            const reports = [];

            reports.push({
                id: 188,
                title: `Activos por dia`,
                group: "Socios"
            })

            reports.push({
                id: 0,
                title: `Nuevos, Bajas y Renovaciones`,
                group: "Socios"
            })



            reports.push({
                id: 1,
                title: `Información de usuarios`,
                group: "Socios"
            })


            reports.push({
                id: 2,
                title: `Encuesta de satisfacción`,
                group: "Socios"
            })

            reports.push({
                id: 4,
                title: `Usuarios en riesgo`,
                group: "Socios"
            })

            reports.push({
                id: 7,
                title: `Transiciones Socio->PaseLibre`,
                group: "Socios"
            })

            reports.push({
                id: 8,
                title: `Ranking de usuarios`,
                group: "Socios"
            })




            reports.push({
                id: 432,
                title: `Asistencias por tipo`,
                group: "Asistencias"
            })

            reports.push({
                id: 3,
                title: `Asistencias por horario`,
                group: "Asistencias"
            })

            /* 

            reports.push({
                id: 2,
                title: `Totales`,
                content: `Nuevas suscripciones: 10`,
                group: "Asistencias"
            })

            reports.push({
                id: 5,
                title: `Asistencias por tipo de cuenta`,
                content: `Nuevas suscripciones: 10`,
                group: "Asistencias"
            })

            reports.push({
                id: 6,
                title: `Asistencias por horario`,
                content: `Nuevas suscripciones: 10`,
                group: "Asistencias"
            })

             reports.push({
                id: 8,
                title: `Asistencias por socio`,
                content: `Nuevas suscripciones: 10`,
                group: "Asistencias"
             })

 */



            reports.push({
                id: 9,
                title: `Alertas por dia y gestion`,
                content: `Nuevas suscripciones: 10`,
                group: "Alertas"
            })









            return reports;
        },
        getReportComponent(title) {
            const componentMap = {
                'Alertas por dia y gestion': Alerts,
                'Nuevos, Bajas y Renovaciones': NewSubscriptions,
                'Encuesta de satisfacción': SatisfactionSurvey,
                'Asistencias por tipo': AssistancesPerUsertypeVue,
                'Asistencias por horario': ScheduleStats,
                'Información de usuarios': UsersAge,
                'Usuarios en riesgo': UsersInRisk,
                'Transiciones Socio->PaseLibre': UserPaselibreTransitions,
                'Ranking de usuarios': UsersRankingVue,
                'Activos por dia': ActiveUsersHistory
            };

            return componentMap[title];
        }
    },
    mounted() {
        //this.fetchReports();
        this.reports = this.generateFakeReports();
    }
};
</script>

<style scoped>
.has-submenu {
    cursor: default;
}

.has-submenu .v-list-item {
    cursor: pointer;
    transition: all 0.2s ease;
}

.v-list-item:hover {
    background: rgba(var(--v-primary-base), 0.04);
    transform: translateX(4px);
}

.v-card {
    transition: all 0.3s ease;
    border: 1px solid rgba(0, 0, 0, 0.05);
}

.v-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1) !important;
}

.v-avatar {
    transition: all 0.3s ease;
}

.v-card:hover .v-avatar {
    transform: scale(1.05);
}

.transition-swing {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
</style>
