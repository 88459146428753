<template>
    <v-container>
        <v-row no-gutters>
            <v-col cols="12">
                <v-alert type="info" color="primary darken-3">
                    <strong>
                        Este reporte muestra los usuarios que no han realizado ningún entrenamiento en el rango de
                        fechas seleccionado y que no tienen una licencia activa.
                    </strong>


                </v-alert>
            </v-col>
            <v-col cols="12" sm="6" md="6">
                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
                    min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="dateRange" hide-details dense label="Select Date Range" filled
                            prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="dateRange" range no-title scrollable
                        :min="dateRange.length > 0 ? dateRange[0] : null">
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="dateRange = []">Cancel</v-btn>
                        <v-btn text color="primary" @click="onDateRangeSelect(); menu = false">Confirm</v-btn>
                    </v-date-picker>
                </v-menu>
            </v-col>
            <v-divider vertical></v-divider>
            <!-- <v-col cols="12" sm="6" md="6">
                <v-autocomplete @change="onDateRangeSelect" dense filled v-model="searchUserType" :items="userTypes"
                    label="Selecciona tipo usuario" hide-details prepend-inner-icon="mdi-account"></v-autocomplete>
            </v-col> -->
            <v-col cols="12">
                <v-data-table :headers="headers" :items="filteredUsers" class="elevation-1" :loading="loading"
                    item-key="id">
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { getFirestore, collection, query, where, getDocs, Timestamp } from 'firebase/firestore';
import moment from 'moment';

export default {
    data() {
        return {
            dateRange: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
            users: [],
            filteredUsers: [],
            loading: false,
            menu: false,
            headers: [
                { text: 'ID', value: 'id' },
                { text: 'Nombre usuario', value: 'displayName' },
                { text: 'Fecha de creación', value: 'createdAt' }
            ],
        };
    },
    mounted() {
        this.fetchUsers();
    },
    methods: {
        async fetchUsers() {
            this.loading = true;
            const db = getFirestore();
            const usersRef = collection(db, 'users');
            const q = query(usersRef, where('disabled', '==', false));
            const querySnapshot = await getDocs(q);
            this.users = querySnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id,
                createdAt: moment(new Date(doc.data().createdAt.seconds * 1000)).format('YYYY-MM-DD')
            }));

            // Remove users with type "admin","entrenador","superuser"
            this.users = this.users.filter(user => user.type !== "admin" && user.type !== "entrenador" && user.type !== "superuser");


            await this.filterUsersWithoutWorkouts();
            this.loading = false;
        },
        async filterUsersWithoutWorkouts() {
            const db = getFirestore();
            const startDate = Timestamp.fromDate(moment(this.dateRange[0]).toDate());
            const endDate = Timestamp.fromDate(moment(this.dateRange[1]).toDate());

            // Get all workouts for the date range
            const workoutsRef = collection(db, 'workouts');
            const workoutsSnapshot = await getDocs(query(
                workoutsRef,
                where('date', '>=', startDate),
                where('date', '<=', endDate)
            ));

            // Create a Set of user IDs who have workouts
            const usersWithWorkouts = new Set(
                workoutsSnapshot.docs.map(doc => doc.data().user_id)
            );

            // Get all users with active licenses
            const usersWithLicensesPromises = this.users.map(async (user) => {
                const licensesRef = collection(db, `users/${user.id}/licenses`);
                const licensesSnapshot = await getDocs(query(
                    licensesRef,
                    where('isActive', '==', true),
                    where('issuedOn', '<=', endDate)
                ));
                return !licensesSnapshot.empty ? user.id : null;
            });

            const usersWithLicenses = new Set(
                (await Promise.all(usersWithLicensesPromises)).filter(id => id !== null)
            );

            // Filter users who have no workouts and no active licenses
            this.filteredUsers = this.users.filter(user =>
                !usersWithWorkouts.has(user.id) && !usersWithLicenses.has(user.id)
            );
        },
        onDateRangeSelect() {
            if (this.dateRange.length === 2) {
                this.fetchUsers();
            }
        }
    }
};
</script>
